import React from "react";
// import classes from "./styles.module.scss";
import Header from "./header";
import Footer from "./footer";
import MiddleContent from "./middleContent";

const LayoutAdmin = (props) => {
    return(
        <div>
            <Header />
            <MiddleContent middle_content={props.children} />
            <Footer />
        </div>
    )
}

export default LayoutAdmin;