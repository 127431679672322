import React, { useEffect } from "react";
import classes from "./styles.module.scss";
import Header from "./header";
import Footer from "./footer";
import MiddleContent from "./middleContent";
import { onLoad } from "./actions";
import { connect } from "react-redux";

const LayoutNew = (props) => {
    useEffect(() => {
        props.onLoad();
      }, []);
      
    return(
        <div>
            <Header userData={props.userProfile.userData} />
            <MiddleContent middle_content={props.children} />
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userProfile:state.userProfile
    };
  };
  
  const mapDispatchToProps = { onLoad };
  
  export default connect(mapStateToProps, mapDispatchToProps)(LayoutNew);