import React from "react";
import classes from "./styles.module.scss";
import FooterLogo from "../../../assets/images/YT CC Logo 1.png";
import Address from "../../../assets/images/address.png";
import Phone from "../../../assets/images/phone.png";
import Mail from "../../../assets/images/mail.png";
import Facebook from "../../../assets/images/facebook.png";
import Youtube from "../../../assets/images/youtube.png";
import Instagram from "../../../assets/images/instagram.png";
import Linkedin from "../../../assets/images/linkedin.png"; 

const Footer = () => {
    return(
        <div className={classes.footer_container} id="contact">
            <div className={classes.footer_content}>
                <div className={classes.menu_list_mobile}>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About Us</a></li>
                    </ul>
                </div>
                <div className={classes.logo_social}>
                    <img className={classes.footer_logo} src={FooterLogo} />
                    {/* <div className={classes.social_list}>
                        <img src={Youtube} />
                        <img src={Instagram} />
                        <img src={Facebook} />
                        <img src={Linkedin} />
                    </div> */}
                </div>
                <div className={classes.contact_us}>
                    <p className={classes.header}>contact us</p>
                    <div className={classes.mail}>
                        <img src={Mail} />
                        {/* <p>campuschampions.inquiries@gmail.com</p> */}
                        <p><a href="mailto:campuschampions.support@madinfluence.com">campuschampions.support@madinfluence.com</a></p>
                    </div>
                    {/* <div className={classes.phone}>
                        <img src={Phone} />
                        <p>+91 9876543210 , (0120) 4640457</p>
                    </div>
                    <div className={classes.address}>
                        <img src={Address} />
                        <p>26-27 B, Sector 1, Noida <br />201301</p>
                    </div> */}
                </div>
                <div className={classes.menu_list}>
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#about">About Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer;