import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import "./hamMenuUser.css";

const HamburgerUser = () => {

    const[sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar)

    return(
        <div>
            <div className="hamburger">
                <MenuRoundedIcon onClick={showSidebar} />
            </div>
            <nav className={sidebar ? 'nav_menu active' : 'nav_menu'}>
                <ul className="nav_menu_items">
                    <li className="nav_bar_toggle">
                        <ClearRoundedIcon onClick={showSidebar} />
                    </li> 
                    <Link to={"/overview"} onClick={showSidebar}>
                        <li class="link">Overview</li>
                    </Link>
                    <Link to={"/workshop"} onClick={showSidebar}>
                        <li class="link">Workshop</li>
                    </Link>
                    <Link to={"/notes"} onClick={showSidebar}>
                        <li class="link">Notes</li>
                    </Link>
                    <Link to={"/experience"} onClick={showSidebar}> 
                        <li class="link">Experience</li>
                    </Link>
                </ul>
            </nav>
        </div>
    )
}

export default HamburgerUser;