import React from "react";
import classes from "./styles.module.scss";

const Button = ({button_text, width, height, padding, borderRadius, icon, icon_two, secondClassName, onClick, ...props}) => {
    return(
        <button className={`${classes[props.variant]} ${secondClassName}`} onClick={onClick}
            style={{width, height, padding, borderRadius}}
            {...props}
        >
            {icon}
            {button_text}
            {icon_two}
        </button>
    )
}

export default Button;