import React, { useState } from "react";
import classes from "./styles.module.scss";
import InputType from "../../components/inputType";
import TicMark from "../../assets/images/Vector 4.png";
import Button from "../../components/button";
import { connect, useDispatch } from "react-redux";
import { onLogin } from '../../store/auth/actions';
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const LoginModalContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [state, setState] = useState({
        emailAddress: "",
        password: "",
    })
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSubmit = (e) => {
        if(!isChecked){
            toast.error('Please agree to the terms before submitting.')
            e.preventDefault();
        }else{
            e.preventDefault();
            dispatch(onLogin(state, navigate))
        }
        // onLogin(state.email, state.password, navigate)
    }

    const handleChange = (name, value) => {
        let _state = { ...state };
        _state[name] = value;
        setState(_state)
    }

    return (
        <div className={classes.login_modal_container}>
            <div className={classes.login_modal_content}>
                <form onSubmit={handleSubmit}>
                    <InputType
                        type="text"
                        variant="input_type_login"
                        autoComplete="off"
                        placeholder="Username or Email*"
                        value={state.emailAddress}
                        onChange={(e) => handleChange("emailAddress", e.target.value)}
                        required
                    />
                    <InputType
                        type="password"
                        value={state.password}
                        variant="input_type_login"
                        autoComplete="off"
                        placeholder="Password*"
                        onChange={(e) => handleChange("password", e.target.value)}
                        required
                    />

                    <div className={classes.checkbox_agreement}>
                        <input type="checkbox" id="agreement" onChange={handleCheckboxChange}
                            checked={isChecked} />
                        <label for="agreement"><img src={TicMark} /></label>
                        <p>I hereby agree to receive updates YouTube Mad Influence Campus Champions via Email and WhatsApp</p>
                    </div>

                    <Button
                        button_text="Log in"
                        variant="blue_purple_three"
                        secondClassName={classes.custom_button}
                    />
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = { onLogin }

export default connect(mapStateToProps, mapDispatchToProps)(LoginModalContent);