import React from "react";
import classes from "./styles.module.scss";
import BrandLogo from "../../../assets/images/YT CC Logo 1.png";
import { Link } from "react-router-dom";
import HamburgerUser from "./hamMenuUser";

const Header = (props) => {
    console.log(props)
    return (
        <div className={classes.header_container}>
            <div className={classes.header_content}>
                <a href={'/'}>
                    <img className={classes.brand_logo} src={BrandLogo} />
                </a>
                <div className={classes.right_profile}>
                    <Link
                        to={{
                            pathname: '/profile',
                            state: { userData: props.userData }
                        }}
                        className={classes.profile_pic}
                    >
                        {
                            props?.userData?.profilePictureUrl ? <img src={props?.userData?.profilePictureUrl} /> : <p className={classes.name_letter}>{props?.userData?.firstName?.charAt(0)} </p>
                        }
                    </Link>
                    <div className={classes.about_person}>
                        <p className={classes.name}>{props?.userData.firstName}</p>
                        <p className={classes.he_him}>{props.userData.emailAddress}</p>
                        <p className={classes.designation}>{props.userData.designation}</p>
                    </div>
                    <div className={classes.ham_menu_list}>
                        <HamburgerUser />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;