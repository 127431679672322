import classes from "./styles.module.scss";

const InputType = ({ value, label, name, placeholder, type, onChange, autoComplete, options, ...props }) => {
    return (
        <>
            {
                type === "textarea" ?
                    <div className={`${classes[props.variant]}`}>
                        {label && <label className={classes.title} htmlFor="input-field">{label}</label>}
                        <textarea
                            value={value}
                            name={name}
                            className="form-control"
                            placeholder={placeholder}
                            onChange={onChange}
                            autoComplete={autoComplete}
                            {...props}
                            rows={5}
                        />
                    </div>
                    :
                    <div className={`${classes[props.variant]}`}>
                        {label && <label className={classes.title} htmlFor="input-field">{label}</label>}
                        <input
                            type={type}
                            value={value}
                            name={name}
                            className="form-control"
                            placeholder={placeholder}
                            onChange={onChange}
                            autoComplete={autoComplete}
                            {...props}
                        />
                    </div>
            }
        </>
    )
}

export default InputType;