
import React, { lazy, useEffect, useState } from "react";
import AppRoute from "./route";
import Layout from "../container/layout";
import LayoutNew from "../container/layoutNew";
import LayoutAdmin from "../container/layoutAdmin";


const HomePage = lazy(() => import("../pages/homePage"));
const ProfilePage = lazy(() => import("../pages/profilePage"));
const OverviewPage = lazy(() => import("../pages/overviewPage"));
const WorkshopPage = lazy(() => import("../pages/workshopPage"));
const NotesPage = lazy(() => import("../pages/notesPage"));
const PerformancePage = lazy(() => import("../pages/performancePage"));
const ExperiencePage = lazy(() => import("../pages/experiencePage"));
const Login = lazy(() => import("../pages/admin/login"))
const AdminWorkSpace = lazy(() => import("../pages/admin/adminWorkSpace"))
const AdminNotes = lazy(() => import("../pages/admin/adminNotes"))
const adminShoutOut = lazy(() => import("../pages/admin/adminShoutOut"))
const adminPanelist = lazy(() => import("../pages/admin/adminPanelist"))
const adminExperience = lazy(() => import("../pages/admin/adminExperience"))
const adminChampions = lazy(() => import("../pages/admin/adminChampions"))

const openRoutes = [
    { path: "/", exact: true, component: HomePage },
    { path: "/admin/login", exact: true, component: Login }
];

const userRoutes = [
    { path: "/", exact: true, component: HomePage },
    { path: "/profile", exact: true, component: ProfilePage },
    { path: "/overview", exact: true, component: OverviewPage },
    { path: "/workshop", exact: true, component: WorkshopPage },
    { path: "/notes", exact: true, component: NotesPage },
    { path: "/performance", exact: true, component: PerformancePage },
    { path: "/experience", exact: true, component: ExperiencePage },
];

const adminRoutes = [
    { path: "/", exact: true, component: HomePage },
    { path: "/admin/workspace", exact: true, component: AdminWorkSpace },
    { path: "/admin/notes", exact: true, component: AdminNotes },
    { path: "/admin/shoutout", exact: true, component: adminShoutOut },
    { path: "/admin/panelist", exact: true, component: adminPanelist },
    { path: "/admin/experience", exact: true, component: adminExperience },
    { path: "/profile", exact: true, component: ProfilePage },
    { path: "/admin/champions", expect: true, component: adminChampions }

]

const PagesRoute = (props) => {
    const [allRoues, setAllRoues] = useState([])

    useEffect(() => {
        setAllRoues([...openRoutes])
    }, [])

    return (
        <>

            {
                window.location.pathname === "/"
                ?
                    <Layout>
                            {openRoutes.map((route, idx) => (
                                <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                            ))}
                        </Layout>
                : <>
                {
                props.layout === "admin" ?
                    <LayoutAdmin>
                        {adminRoutes.map((route, idx) => (
                            <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                        ))}
                    </LayoutAdmin>
                    : props.layout === "user" ?
                        <LayoutNew>
                            {userRoutes.map((route, idx) => (
                                <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                            ))}
                        </LayoutNew>
                        : null
                        
                }
                </>
            }

        </>
    );
};

export default PagesRoute;
