import React from "react";
import classes from "./styles.module.scss";

const Footer = () => {
    return(
        <div className={classes.footer_container}>
            <div className={classes.footer_content}>
                <p>By using Mad Influence YouTube Campus Champions and its services, you agree to its Terms & Condition and Privacy policy</p>
            </div>
        </div>
    )
}

export default Footer;