import { combineReducers } from "redux";

import Auth from "./auth/reducer"
import Notification from "./notification/reducer"
import adminWorkspace from "../pages/admin/adminWorkSpace/reducer"
import adminNotes from "../pages/admin/adminNotes/reducer";
import adminShoutOut from "../pages/admin/adminShoutOut/reducer"
import adminPanelist from "../pages/admin/adminPanelist/reducer"
import adminExperience from "../pages/admin/adminExperience/reducer"
import adminChampions from "../pages/admin/adminChampions/reducer"
import userProfile from "../container/layoutNew/reducer"
import userWorkShop from "../pages/workshopPage/reducer"
import userNotes from "../pages/notesPage/reducer"
import userExperiance from "../pages/experiencePage/reducer"
import panelist from "../container/homeContainer/panelists/reducer"
import champions from "../container/homeContainer/championsCommunity/reducer"
import testimonial from "../container/homeContainer/communityTestimonials/reducer"
import shoutout from "../container/homeContainer/registerNowContainer/reducer"



const rootReducer = combineReducers({
    Auth,
    Notification,
    adminWorkspace,
    adminNotes,
    userProfile,
    userWorkShop,
    userNotes,
    adminShoutOut,
    userExperiance,
    adminPanelist,
    adminExperience,
    adminChampions,
    panelist,
    champions,
    testimonial,
    shoutout
})


export default rootReducer;