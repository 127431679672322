import React, { useState } from "react";
import classes from "./styles.module.scss";
import BrandLogo from "../../../assets/images/YT CC Logo 1.png";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Hamburger from "./hamMenu";
import Button from "../../../components/button";
import CustomModal from "../../../components/customModal";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LoginModalContent from "../../loginModalContent/iindex";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getDataFromStore } from "../../../store/getStore";

const Header = () => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // useEffect(()=>{
    //     let data = getDataFromStore("Auth")
    //     if(data){
    //         console.log(data)
    //     }
    // },[])

    return (
        <div className={classes.header_container} id="home">
            <div className={classes.header_content}>
                <Link to={'/'}>
                    <img className={classes.brand_logo} src={BrandLogo} />
                </Link>
                <div className={classes.right_content}>
                    <ul className={classes.menu_list}>
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                    <Button
                        button_text="Log in"
                        variant="blue_purple_three"
                        onClick={handleOpen}
                        secondClassName={classes.header_login_button}
                    />
                    <div className={classes.menu_list_mobile}>
                        <Hamburger />
                    </div>
                </div>
            </div>
            <CustomModal
                open={open}
                handleClose={handleClose}
                content={<LoginModalContent />}
            />
        </div>
    )
}

export default Header;