import React from "react";

export const HamburgerData = [
    {
        title: "About Us",
        path: "#about"
    },
    {
        title: "Contact Us",
        path: "#contact"
    }
]