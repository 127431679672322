import React from "react";
import classes from "./styles.module.scss";
import Overview from "../../../assets/images/menuLogos/Group 10233.png";
import Workshop from "../../../assets/images/menuLogos/Union.png";
import Notes from "../../../assets/images/menuLogos/Union (1).png";
import Performance from "../../../assets/images/menuLogos/Union (2).png";
import Experience from "../../../assets/images/menuLogos/Union (3).png";
import { NavLink } from "react-router-dom";
import "./active.css"

const   MiddleContent = (props) => {
    return(
        <div className={classes.middle_container}>
            <div className={classes.middle_content}>
                <div className={classes.left_nav_menus}>
                    <ul>
                        <NavLink to={"/overview"} activeClassName="activeLink">
                            <li>
                                <img src={Overview} />
                                <p>Overview</p>
                            </li>
                        </NavLink>
                        <NavLink to={"/workshop"} activeClassName="activeLink">
                            <li>
                                <img src={Workshop} />
                                <p>Workshop</p>
                            </li>
                        </NavLink>
                         
                        <NavLink to={"/notes"} activeClassName="activeLink">
                            <li>
                                <img src={Notes} />
                                <p>Notes</p>
                            </li>
                        </NavLink>
                        <NavLink to={"/performance"} activeClassName="activeLink">
                            <li>
                                <img src={Performance} />
                                <p>Performance</p>
                            </li>
                        </NavLink>
                        <NavLink to={"/experience"} activeClassName="activeLink">
                            <li>
                                <img src={Experience} />
                                <p>Experience</p>
                            </li>
                        </NavLink>
                    </ul>
                </div>
                <div className={classes.right_content_view}>
                    {props.middle_content}
                </div>
            </div>
        </div>
    )
}

export default MiddleContent;