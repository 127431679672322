import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import classes from "./styles.module.scss";

const defaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const CustomModal = ({ open, handleClose, title, content, style, cross_icon }) => {
  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={{ ...defaultStyle, ...style }}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {content}
            </Typography> */}
            <div className={classes.modal_content}>
                <div className={classes.modal_header}>
                    <div>
                        {title}
                    </div>
                    <div>
                        {cross_icon}
                    </div>
                </div>
                <div className={classes.content}>
                    {content}
                </div>
            </div>
        </Box>
    </Modal>
  );
}

export default CustomModal;