import toast from "react-hot-toast";
import { apiFunction } from "../../apiCalls/functions"
import {GET_USER} from "../../apiCalls/urls/userProfile"
import { LoadingEnd, LoadingStart } from "../../components/loading";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action"
import * as actionTypes from './actionTypes';

export const fetchUserProfile = async () => {
    return await apiFunction(GET_USER, 'get', {}, true, false)
}


export const onLoad = () => {
    return async (dispatch) => {
        dispatch(onFetchUserProfile())
    }
}

export function onFetchUserProfile() {
    return async (dispatch) => {
        LoadingStart()
        let result = await fetchUserProfile();
        if (result.status) {
            dispatch(updateUserProfile(result.data))
            // console.log(result.data.workshops)
        }
        else {
           toast.error(result.error)
        }
        LoadingEnd();
    }
}

export const updateUserProfile = (data) => {
    return {
        type: actionTypes.UPDATE_USER_PROFILE,
        payload: { data }
    };
}