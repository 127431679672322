import * as actionTypes from './actionTypes';
import { apiFunction } from "../../apiCalls/functions"
import { ADMIN_LOGIN } from "../../apiCalls/urls/login"
import { LoadingStart, LoadingEnd } from "../../components/loading"
import toast from 'react-hot-toast';

export const fetchLoginDetails = async (data) => {
    return await apiFunction(ADMIN_LOGIN, 'post', data, false, false)
}

export function onLogin(data, navigate) {
    return async (dispatch) => {
        LoadingStart()
        console.log(data)
        if (data.emailAddress !== '' && data.password !== '') {
            let result = await fetchLoginDetails(data);
            if (result.status) {
                localStorage.setItem("campus-admin-login-data", JSON.stringify(result.data))
                dispatch(updateLoginDetails(result.data))
                if (result.data.user.designation === 'ADMIN') {
                    navigate('/admin/workspace');
                } else {
                    navigate('/overview');
                }
            }
            else {
                toast.error(result.error);
            }
        } else {
            toast.error("Please Fill Email-Address and Password");
        }

        LoadingEnd()
    }
}

// ACTIONS
export const updateLoginDetails = (data) => {
    return {
        type: actionTypes.UPDATE_LOGIN_DETAILS,
        payload: { data }
    };
}

export const importDataFromLocal = () => {
    return (dispatch) => {
        let data = localStorage.getItem("campus-admin-login-data")
        // console.log(data)
        if (data) {
            data = JSON.parse(data)

            dispatch(updateLoginDetails(data))
        }
    }
}