import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import BrandLogo from "../../../assets/images/YT CC Logo 1.png";
import { Link } from "react-router-dom";
import { getDataFromStore } from "../../../store/getStore";

const Header = () => {
    const[profileData,setProfileData] = useState()
    
    useEffect(()=>{
        let userProfile = getDataFromStore("Auth");
        setProfileData(userProfile?.authDetails?.user)
    })

    return(
        <div className={classes.header_container}>
            <div className={classes.header_content}>
                <a href={'/'}>
                <img className={classes.brand_logo} src={BrandLogo} />
                </a>
                <div className={classes.right_profile}>
                    <Link to={"/profile"} className={classes.profile_pic}>
                        {/* <img src={BrandLogo} /> */}
                        <p className={classes.name_letter}>{profileData?.firstName.charAt(0)}</p>
                    </Link>
                    <div className={classes.about_person}>
                        <p className={classes.name}>{profileData?.firstName} </p>
                        <p className={classes.he_him}>{profileData?.emailAddress}</p>
                        <p className={classes.designation}>{profileData?.designation}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;