import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from "./routes";
import { connect } from 'react-redux';

import { importDataFromLocal } from "./store/auth/actions"
import { Toaster } from 'react-hot-toast';

function App(props) {


    useEffect(() => {
        props.importDataFromLocal()
    }, [])
    const checkSection = () => {
        let keys = Object.keys(props.auth.authDetails)
        if (keys.length) {
            if (props.auth.authDetails?.user?.designation === 'ADMIN') {
                return "admin"
            }
            else {
                return "user"
            }
        }
        else {
            return false
        }
    }

    console.log(checkSection(), props.auth.authDetails?.user?.designation);
    return (
        <div className="App">
            <Toaster position="top-right"
                reverseOrder={false} />
            <Routes
                layout={checkSection()}

            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

const mapDispatchToProps = { importDataFromLocal }

export default connect(mapStateToProps, mapDispatchToProps)(App);