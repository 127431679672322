import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { HamburgerData } from "./hamData";
import "./style.css";

const Hamburger = () => {

    const[sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar) 
    

    return(
        <div>
            <div className="hamburger">
                <MenuRoundedIcon onClick={showSidebar} />
            </div>
            <nav className={sidebar ? 'nav_menu active' : 'nav_menu'}>
                <ul className="nav_menu_items">
                    <li className="nav_bar_toggle">
                        <ClearRoundedIcon onClick={showSidebar} />
                    </li> 
                    {
                        HamburgerData.map((item, index) =>{
                            return(
                                <li key={index} className={item.cName} onClick={showSidebar}>
                                    <a href={item.path} className="link">
                                        {item.title}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </nav>
        </div>
    )
}

export default Hamburger;